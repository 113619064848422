<template>
  <div>

    <Table
      :body="{}"
      :title="$t(title)"
      :fields="fieldsToShow"
      :url="url"
      :showSearch="true"
      @filterCompanies="filterCompanies"
      :actionButton="button"
      @actionButtonTab="actionButtonTab"
      :key="initTable"
    >
      <template #cell(id)="data">
        <b-link :to="'/company/' + data.item.id" style="width:55px;" class="badge badge-light-primary">
        C-{{ data.item.id }}
        </b-link>
      </template>
      <template #cell(name)="data">
        <b-link :to="'/company/' + data.item.id">
          <ScoringStatus :scoring="data.item.scoring" />
          <span v-if="data.item.role == 'buyer' && !data.value && data.item.main_contact_person[0].user">{{ data.item.main_contact_person[0].user.name }} {{ data.item.main_contact_person[0].user.last_name }}</span>
          <span v-else>{{data.value}}</span>
          {{ data.value }}
        </b-link>
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | formatDate }}
      </template>
      <template #cell(type)="data">
        <span v-if="data.item && (data.item.role == 'debtor' || data.item.role == 'buyer')" class="d-flex">
          <feather-icon
            icon="CodesandboxIcon"
            class="text-primary"
            height="21"
          ></feather-icon>
          {{ data.item.role == 'debtor' ? 'Debtor' : 'Buyer' }}
        </span>
        <span v-else-if="data.item && data.item.role == 'seller'" class="d-flex">
          <feather-icon
            icon="CodesandboxIcon"
            class="text-success"
            height="21"
          ></feather-icon>
          Seller
        </span>
        <!-- <span v-if="data.item && data.item.buyer == 1">
          <feather-icon
            icon="CodesandboxIcon"
            class="text-success"
            variant="success"
          ></feather-icon>
        </span> -->
      </template>
      <template #cell(status)="data">
        <span v-if="data.value == 0" class="text-warning"
          >{{ $t("pending") }}
        </span>
        <span v-if="data.value == 1" class="text-success"
          >{{ $t("active") }}
        </span>
        <span v-if="data.value > 1" class="text-danger">{{ $t("inactive") }}</span>
      </template>

      <template #cell(address)="data">
        {{ data.item.zip }},{{ data.item.city }}
      </template>

      <template #cell(custom)="data">
        <div v-if="$route.path === '/'" >
          <!-- <MainTextAlert :nextStepText="data.item.next_step[0].title" /> -->
        </div>
        <div style="text-align: center">
          <b-link :to="'/company/' + data.item.id">
            <b-button variant="outline-secondary" size="sm" v-b-tooltip.hover :title="$t('search')">
              <feather-icon icon="SearchIcon"></feather-icon>
            </b-button>
          </b-link>
          <b-link :to="'/company/' + data.item.id">
            <b-button variant="outline-primary" size="sm" style="margin-left: 5px;margin-right: 5px;"  v-b-tooltip.hover :title="$t('edit')">
              <feather-icon icon="EditIcon"></feather-icon>
            </b-button>
          </b-link>
          <b-button variant="outline-danger" size="sm" v-b-modal.v-b-modal.approveModal @click="changeModalInfo('delete', 'Delete', 'Are you sure that you want to delete this Company?', data.item.id)" v-b-tooltip.hover :title="$t('delete')">
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </div>
      </template>
    </Table>
    <ApproveModal :textContent="modalText" :title="modalTitle" />
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BTr,
  BTh,
  BButton,
  BLink,
} from "bootstrap-vue";

import CustomLoader from "@/components/Common/CustomLoader";
import Table from "@/components/Common/Table2";
import ScoringStatus from "@/components/Company/ScoringStatus";
import ApproveModal from "@/components/Common/ApproveModal";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import MainTextAlert from '@/components/Buyers/MainTextAlert.vue'

export default {
  name: "CompaniesList",
  components: {
    MainTextAlert,
    ScoringStatus,
    ApproveModal,
    Table,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
    CustomLoader,
  },
  props:['listStatus'],
  data() {
    return {
      button: {
        text: "Add new",
        link: "",
        color: "primary",
      },
      initTable: 'init',
      modalText: "",
      modalTitle: "",
      title: "companies",
      url: "/company/admin/list",
      options: null,
      fields: [
        {
          label: "created_at",
          align: "start",
          key: "created_at",
        },
        { label: "id", align: "start", key: "id", sortable: true },
        { label: "Type", align: "start", key: "type", sortable: false },
        { label: "uid", align: "start", key: "uid", sortable: false },
        // { label: "Next Step", key: "nextStepStatusText",},
        {
          label: "name",
          align: "start",
          key: "name",
          sortable: true,
          thStyle: { width: "35%" },
        },
        { label: "address", key: "address", sortable: true },
        { label: "status", key: "status", customSlot: true },
        {
          label: "",
          customSlot: true,
          key: "custom",
          class: "text-right minWidth220",
        },
      ],
      items: [],
      search: "",
      page: 1,
      text:'',
      pageCount: 0,
      itemsPerPage: 12,
      status: [],
      isLoading: false,
      totalItems: 0,
      approveModalAction: null,
      updateTable: false,
      nextStepText: ''
    };
  },
  computed: {
    rows() {
      return this.totalItems;
    },
    fieldsToShow() {
      const hiddenColumns = ["created_at", "id", "address"];
      const isHomePage = this.$route.path === "/";
      return isHomePage
          ? this.fields.filter((field) => !hiddenColumns.includes(field.key))
          : this.fields;
    },
  },
 async created() {
    if(this.listStatus=='all'){
      this.url = "/company/admin/list";
    }
    if(this.listStatus=='pending'){
      this.url = "/company/admin/pending";
    }
    await this.getApiData();
  },
  methods: {
    // fetchNextStepStatus(item) {
    //   this.$http.post(`/nextStep/show/${item.id}`).then((res) => {
    //     if (res.data.value) {
    //       this.text = res.data.value.title;
    //       console.log(item.nextStepStatusText);
    //     } else if (res.data.message) {
    //       item.nextStepStatusText = res.data.message;
    //       console.log(item.nextStepStatusText);
    //     } else {
    //       item.nextStepStatusText = "Loading"; // Set a default value if no data is available
    //     }
    //   }).catch((err) => {
    //     item.nextStepStatusText = "Error"; // Set an error message if the API call fails
    //   });
    // },
    actionButtonTab(){
      this.$router.push('/companies/add-new')
    },
    callApproveModalAction() {
     this.deleteCompany(this.idToChange);
    },
    changeModalInfo(action, title, text, id) {
      this.idToChange = id;
      if (action === 'delete') {
        this.modalText = text;
        this.modalTitle = title;
      }else {
        this.approveModalAction = action;
        this.modalText = text;
        this.modalTitle = title;
      }
    },
    deleteCompany(id) {
      this.$http.post(`company/admin/update/status/${id}/decline`).then((res) => {
        if (res) {
            this.initTable = Math.random();
          // this.items.findIndex(item => item.id === id)
          // if(itemIndex != -1) {s
          //   this.items.splice(itemIndex, 1)
          //   this.initTable = Math.random();
          // }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Company deleted successfully",
              icon: "SuccessIcon",
              variant: "success",
            },
          });

        }
      });
      if (this.$route.path !== "/companies") {
        router.push('/companies');
      }
    },
    filterCompanies(type) {
      if (type != 3) {
        this.$http
          .post(this.url, { role: type })
          .then((response) => {
            this.items = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.page = response.data.value.current_page;
            this.updateTable = true;
          });
      } else {
        this.getApiData();
      }
    },
    getApiData() {
      this.items = [];
      this.isLoading = true;
      this.$http
        .post(this.url+"?page=" + this.page, {
          status: this.status,
          options: null,
          filters: this.filters,
          search: this.search,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.items = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.page = response.data.value.current_page;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
